var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "Quotation" }, [
      _c("div", { staticClass: "mainBox" }, [
        _c("div", { staticClass: "form join Done" }, [
          _vm._m(0),
          _c("h2", [_vm._v("구매해 주셔서 감사합니다.")]),
          _c("div", { staticClass: "btnWrap" }, [
            _c(
              "button",
              { staticClass: "btn full secondary", on: { click: _vm.submit } },
              [_vm._v("확인")]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "logo" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/reviewmind/logo.png"),
          alt: "로고"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }